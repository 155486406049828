import React from "react";
import tw, { css } from "twin.macro";
import PropTypes from "prop-types";

/**
 * -----------------------------------------------------------------------------
 * Receive a CSS grid wrapper to style guide spec.
 * @param  {node}   children  Inner JSX
 * @param  {object} inject    Additional Emotion/Tailwind CSS
 * @param  {string} node      Wrapper JSX node type (defaults to <div>)
 * @return {node}             The resulting CSS grid node
 */
const Grid = ({ children, inject, node }) => {
  const G = `${node}`;

  return (
    <G
      css={[
        css`
          width: 100%;
          max-width: 1600px;
          position: relative;
          display: grid;
          grid-template-columns: repeat(12, minmax(0, 1fr));
          column-gap: 0.5rem;
          margin: 0 auto;
          padding-right: 0.75rem;
          padding-left: 0.75rem;

          @media screen and (min-width: 1025px) {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
          }
        `,
        inject?.tw || tw``
      ]}
    >
      {children}
    </G>
  );
};

Grid.defaultProps = {
  inject: null,
  node: `div`
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  node: PropTypes.string
};

export default Grid;
