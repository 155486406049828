/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  getInventoryIdByVariantSku,
  getInventoryLevelsByIds
} from "~utils/shopify";

export const CacheContext = createContext({});

const CacheProvider = ({ children }) => {
  const [inventory, setInventory] = useState(null);
  const [inventoryFetched, setInventoryFetched] = useState(false);

  //

  const refreshInventory = (products, adminProducts) => {
    if (
      typeof window === `undefined` ||
      typeof fetch === `undefined` ||
      window.location.href.includes(`localhost`) ||
      !products?.edges?.[0] ||
      !adminProducts?.edges?.[0]
    ) {
      setInventory({});
      return;
    }

    const variantsByInventoryId = {};

    products.edges.forEach(({ node }) => {
      const sanityProduct = node;

      if (sanityProduct?.variants?.[0]) {
        sanityProduct.variants.forEach((variant) => {
          if (!variant?.sourceData?.sku) {
            return;
          }

          const inventoryId = getInventoryIdByVariantSku(
            adminProducts.edges,
            variant.sourceData.sku
          );

          if (inventoryId && inventoryId !== ``) {
            variantsByInventoryId[inventoryId] = variant;
          }
        });
      }
    });

    //

    const saveInventoryBatch = async (response) =>
      new Promise((resolve, reject) => {
        const inventoryBatch = {};

        response.json().then((rawInventory) => {
          if (!rawInventory?.inventory_levels?.[0]) {
            reject(new Error(`Couldn't find inventory levels`));
            return;
          }

          rawInventory.inventory_levels.forEach((inventoryLevel) => {
            const variant =
              variantsByInventoryId[inventoryLevel.inventory_item_id];

            if (typeof inventoryLevel.available === `undefined`) {
              inventoryBatch[variant.id] = 0;

              return;
            }

            inventoryBatch[variant.id] = inventoryLevel.available;
          });

          resolve(inventoryBatch);
        });
      });

    getInventoryLevelsByIds(Object.keys(variantsByInventoryId)).then(
      async (responses) => {
        const batchArray = [];

        await Promise.all(
          responses.map(async (response) => {
            const batchedInventory = await saveInventoryBatch(
              response,
              variantsByInventoryId
            );

            batchArray.push(batchedInventory);
          })
        );

        const aggregatedInventory = {};

        batchArray.forEach((batch) => {
          Object.assign(aggregatedInventory, batch);
        });

        setInventory(aggregatedInventory);
      }
    );
  };

  useEffect(() => {
    if (inventory) {
      setInventoryFetched(true);
    }
  }, [inventory]);

  return (
    <CacheContext.Provider
      value={{
        inventory,
        setInventory,
        refreshInventory,
        inventoryFetched,
        setInventoryFetched
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

CacheProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CacheProvider;
