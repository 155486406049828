/* eslint-disable no-console */

/**
 * -----------------------------------------------------------------------------
 * GA4 data schema parser [todo: document]
 * @param  ...
 * @return ...
 */
const productToItem = (product, index = 1, quantity = 1) => {
  let item = null;

  // todo : more validation
  if (!product?.sourceData || !product?.variant) {
    return item;
  }

  const { title, handle, vendor } = product?.sourceData;
  const { variant } = product;
  const { id: variantId, sku, title: variantTitle } = variant;
  const price = variant?.sourceData?.priceV2?.amount || 0;

  item = {
    item_name: title,
    item_id: variantId,
    item_brand: vendor,
    item_category: handle,
    item_variant: variantTitle,
    item_list_id: sku,
    index,
    price,
    quantity
  };

  return item;
};

/**
 * -----------------------------------------------------------------------------
 * dataLayer wrapper [todo: document]
 * @param  ...
 * @return ...
 */
const getProductWithVariantById = (variantId, allSanityShopifyProduct) => {
  let productWithVariant = null;

  if (!allSanityShopifyProduct?.edges?.[0]) {
    return productWithVariant;
  }

  // todo : better js find/filter query
  allSanityShopifyProduct.edges.forEach(({ node }) => {
    if (productWithVariant || !node?.variants?.[0]) {
      return;
    }

    node.variants.forEach((variant) => {
      if (productWithVariant || !variant?.id) {
        return;
      }

      if (variant.id === variantId) {
        productWithVariant = {
          ...node,
          variant
        };
      }
    });
  });

  return productWithVariant;
};

/**
 * -----------------------------------------------------------------------------
 * dataLayer push wrapper [todo: document]
 * @param  ...
 * @return ...
 */
export const sendToDataLayer = (event, data, quantity = 1) => {
  const gtmObj = { event, data, quantity };

  if (typeof window === `undefined` || !window?.dataLayer) {
    if (process.env.NODE_ENV !== `production`) {
      console.log(`[trigger]: `, gtmObj);
    }

    return null;
  }

  if (process.env.NODE_ENV !== `production`) {
    console.log(`[sending to dataLayer]: `, gtmObj);
  }

  return window.dataLayer.push(gtmObj);
};

/**
 * -----------------------------------------------------------------------------
 * Capture product view events [todo: document]
 * @param  ...
 * @return ...
 */
export const trackViewProduct = (productWithVariant) =>
  !productWithVariant?.variant
    ? null
    : sendToDataLayer(`view_item`, {
        ecommerce: {
          items: [productToItem(productWithVariant)]
        }
      });

/**
 * -----------------------------------------------------------------------------
 * Capture cart view events [todo: document]
 * @param  ...
 * @return ...
 */
export const trackViewCart = (cart, allSanityShopifyProduct) => {
  if (!cart?.[0] || !allSanityShopifyProduct?.edges?.[0]) {
    return null;
  }

  const cartItems = [];

  let subtotal = 0;

  cart.forEach(({ variantId, quantity }) => {
    const productWithVariant = getProductWithVariantById(
      variantId,
      allSanityShopifyProduct
    );

    const price = productWithVariant?.variant?.sourceData?.priceV2?.amount || 0;

    subtotal += parseFloat(price * quantity);

    cartItems.push(productToItem(productWithVariant, 1, quantity));
  });

  return sendToDataLayer(`view_cart`, {
    items: cartItems,
    value: subtotal
  });
};

/**
 * -----------------------------------------------------------------------------
 * Capture add to cart product events [todo: document]
 * @param  ...
 * @return ...
 */
export const trackAddToCart = (productWithVariant, quantity = 1) =>
  !productWithVariant?.variant
    ? null
    : sendToDataLayer(
        `add_to_cart`,
        {
          ecommerce: {
            items: [productToItem(productWithVariant, 1, quantity)]
          }
        },
        quantity
      );

/**
 * -----------------------------------------------------------------------------
 * Capture remove from cart product events [todo: document]
 * @param  ...
 * @return ...
 */
export const trackRemoveFromCart = (productWithVariant) =>
  !productWithVariant?.variant
    ? null
    : sendToDataLayer(`remove_from_cart`, {
        ecommerce: {
          items: [productToItem(productWithVariant)]
        }
      });

/**
 * -----------------------------------------------------------------------------
 * Capture begin checkout events [todo: document]
 * @param  ...
 * @return ...
 */
export const trackBeginCheckout = (cart, allSanityShopifyProduct) => {
  if (!cart?.[0] || !allSanityShopifyProduct?.edges?.[0]) {
    return null;
  }

  const cartItems = [];

  cart.forEach(({ variantId, quantity }) => {
    const productWithVariant = getProductWithVariantById(
      variantId,
      allSanityShopifyProduct
    );

    cartItems.push(productToItem(productWithVariant, 1, quantity));
  });

  return sendToDataLayer(`begin_checkout`, {
    ecommerce: {
      items: cartItems
    }
  });
};
