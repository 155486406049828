/* eslint-disable import/prefer-default-export */

import _ from "underscore";
import { fancyWarning } from "~utils/helpers";

/* ===========================================================================
 * getCheckoutURL
 */

export async function getCheckoutURL(cart, currencyCode) {
  const url = `${process.env.GATSBY_NETLIFY_FUNCTIONS}/shopify-storefront-graphql`;

  let lineItemsString = `[`;

  cart.forEach((cartItem, cartItemIndex) => {
    let prefix = ``;

    if (cartItemIndex !== 0) {
      prefix = `, `;
    }

    lineItemsString += `${prefix}{variantId: "${cartItem.variantId.replace(
      `Shopify__ProductVariant__`,
      ``
    )}", quantity: ${cartItem.quantity}}`;
  });

  lineItemsString += `]`;

  const query = `
    mutation {
      checkoutCreate(
        input: {
          lineItems: ${lineItemsString},
          presentmentCurrencyCode: ${currencyCode}
        }
      ) {
        checkout {
          id
          webUrl
        }
      }
    }
  `;

  const response = await fetch(url, {
    body: JSON.stringify(query),
    headers: new Headers({
      "Content-Type": `application/json`
    }),
    method: `POST`
  });

  return response;
}

/* ===========================================================================
 * getCartProduct
 */

export function getCartProduct(sanityProduct, userSelectedOptions) {
  const userSelectedOptionKeys = Object.keys(userSelectedOptions);
  const scoreThreshold = userSelectedOptionKeys.length;

  let cartProduct = null;

  if (!sanityProduct?.variants?.[0]) {
    return cartProduct;
  }

  sanityProduct.variants.forEach((variant) => {
    if (cartProduct || !variant?.sourceData?.selectedOptions?.[0]) {
      return;
    }

    let score = 0;

    variant.sourceData.selectedOptions.forEach((variantOption) => {
      if (cartProduct) {
        return;
      }

      userSelectedOptionKeys.forEach((userSelectedOptionKey) => {
        if (cartProduct) {
          return;
        }

        const userSelectedOption = userSelectedOptions[userSelectedOptionKey];

        if (
          variantOption?.name?.toLowerCase() ===
            userSelectedOptionKey.toLowerCase() &&
          variantOption?.value?.toLowerCase() ===
            userSelectedOption.toLowerCase()
        ) {
          score += 1;
        }
      });
    });

    if (score >= scoreThreshold) {
      cartProduct = {
        ...sanityProduct,
        variant,
        variants: null
      };
    }
  });

  return cartProduct;
}

/* ===========================================================================
 * getInventoryIdByVariantSku
 */

export function getInventoryIdByVariantSku(adminProducts, variantSku) {
  let id;

  adminProducts.forEach(({ node }) => {
    if (id || !node?.products?.[0]) {
      return;
    }

    node.products.forEach((product) => {
      if (id || !product?.variants?.[0]) {
        return;
      }

      product.variants.forEach((variant) => {
        if (id) {
          return;
        }

        if (variant.sku === variantSku) {
          id = variant.inventory_item_id;
        }
      });
    });
  });

  return id;
}

/* ===========================================================================
 * getInventoryLevelsByIds
 */

export async function getInventoryLevelsByIds(ids) {
  if (
    typeof window === `undefined` ||
    typeof fetch === `undefined` ||
    !process.env.GATSBY_NETLIFY_FUNCTIONS ||
    !process.env.GATSBY_REGION_CODE
  ) {
    return null;
  }

  const url = `${process.env.GATSBY_NETLIFY_FUNCTIONS}/get-shopify-inventory`;

  //

  const batchSize = 50;

  const batches = _.map(ids, (id, index) =>
    index % batchSize === 0 ? ids.slice(index, index + batchSize) : null
  ).filter((id) => id);

  //

  const promises = [];

  batches.forEach((batch) => {
    const batchedIds = batch.join();

    promises.push(
      fetch(url, {
        body: JSON.stringify({
          ids: batchedIds
        }),
        headers: new Headers({
          "Content-Type": `application/json`
        }),
        method: `POST`
      })
    );
  });

  return Promise.all(promises);
}

/* ===========================================================================
 * getPriceByCurrency
 */

export function getPriceByCurrency(variant, currencyCode) {
  let price;

  if (!variant?.presentmentPrices?.edges?.[0]) {
    fancyWarning(`Presentment prices not found`);

    ({ price } = variant);
  } else {
    variant.presentmentPrices.edges.forEach(({ node }) => {
      if (price) {
        return;
      }

      if (node.price.currencyCode === currencyCode) {
        price = node.price.amount;
      }
    });
  }

  if (currencyCode === `JPY`) {
    return parseInt(price);
  }

  return parseFloat(price).toFixed(2);
}
