/* eslint-disable import/prefer-default-export */
import React from "react";
import PropTypes from "prop-types";
import tw, { css } from "twin.macro";

// todo : color
// css={[
//   css`
//     ${inject?.css || ``}
//   `,
//   inject?.tw || tw``
// ]}
// style="enable-background:new 0 0 165 20;"
export const FooterLogo = ({ color, inject }) => (
  <svg x="0px" y="0px" viewBox="0 0 165 20" xmlSpace="preserve">
    <g>
      <path
        d="M6.4,1.6c-2.7,0-4.7,2.6-4.7,7.1s2,7.1,4.7,7.1s4.7-2.6,4.7-7.1S9.1,1.6,6.4,1.6z M6.4,14.8
		c-1,0-3.1-0.6-3.1-6.1s2.1-6.1,3.1-6.1s3.1,0.6,3.1,6.1S7.4,14.8,6.4,14.8z"
      />
      <path
        d="M19.2,11c0,2.7-1.6,3.9-2.9,3.9c-0.9,0-2.2-0.5-2.2-2.5v-7h-1.3v7.2c0,2.5,1.7,3.2,3.3,3.2
		c1.3,0,2.4-0.7,3.1-1.8v1.4h1.3V5.4h-1.3V11z"
      />
      <path
        d="M24.5,3h-1c0,1.6-0.3,2.5-1.9,2.6v0.7h1.5V14c0,1.5,1,2.2,2.7,1.7v-1c-0.9,0.3-1.4,0-1.4-0.8V6.4h1.9V5.4h-1.9
		V3z"
      />
      <path
        d="M31.4,9.8c-1.3-0.5-2.6-0.9-2.6-2.1c0-0.8,0.6-1.5,2-1.5c1.6,0,2.1,1.3,1.9,2.2H34c0.2-1.8-1.2-3.2-3.2-3.2
		c-2,0-3.4,1.1-3.4,2.7c0,1.5,1.3,2.3,2.9,2.9c1.3,0.5,2.7,0.9,2.7,2.2c0,1.3-1.2,1.9-2.3,1.9c-1.3,0-2.6-0.8-2.2-2.7h-1.4
		c-0.3,2.2,1.3,3.7,3.5,3.7c2.1,0,3.7-1.2,3.7-3.1C34.4,10.9,32.6,10.2,31.4,9.8z"
      />
      <path
        d="M37.7,3h-1c0,1.6-0.3,2.5-1.9,2.6v0.7h1.5V14c0,1.5,1,2.2,2.7,1.7v-1c-0.9,0.3-1.4,0-1.4-0.8V6.4h1.9V5.4h-1.9
		V3z"
      />
      <path
        d="M44,5.1c-2.2,0-3.8,1.2-3.7,3.6h1.3c-0.2-1.8,1-2.6,2.2-2.6c1.4,0,2.5,0.7,2.5,2.3V9L44,9.7
		c-1.9,0.5-4,1.1-4,3.5c0,1.6,1.5,2.6,3.2,2.6c1.6,0,2.6-0.8,3.2-1.8v1.4h1.3V8.2C47.7,6.7,46.7,5.1,44,5.1z M46.4,11.6
		c0,1.3-0.9,3.3-3,3.3c-1.3,0-2-0.9-2-1.8c0-1.8,1.4-2,5-3V11.6z"
      />
      <path
        d="M54.2,5.1c-1.3,0-2.4,0.6-3.1,1.7V5.4h-1.3v10.1h1.3V10c0-2.7,1.6-3.9,2.9-3.9c1,0,2.2,0.6,2.2,2.5v6.8h1.3
		V8.4C57.4,5.9,55.7,5.1,54.2,5.1z"
      />
      <path
        d="M65.4,6.8c-0.9-1.3-2-1.7-2.7-1.7c-2.2,0-3.9,2.1-3.9,5.2s1.6,5.5,3.8,5.5c0.8,0,2-0.3,2.8-1.8v1.4h1.3V2h-1.3
		V6.8z M62.8,14.9c-0.9,0-2.5-0.6-2.5-4.5s1.7-4.3,2.5-4.3c0.9,0,2.7,0.9,2.7,4.4C65.4,13.9,63.8,14.9,62.8,14.9z"
      />
      <rect x="69.2" y="5.4" width="1.3" height="10.1" />
      <path d="M69.8,2.1c-0.6,0-1,0.5-1,1.1c0,0.6,0.5,1.1,1,1.1c0.6,0,1-0.5,1-1.1C70.9,2.6,70.4,2.1,69.8,2.1z" />
      <path
        d="M77.3,5.1c-1.3,0-2.4,0.6-3.1,1.7V5.4h-1.3v10.1h1.3V10c0-2.7,1.6-3.9,2.9-3.9c1,0,2.2,0.6,2.2,2.5v6.8h1.3
		V8.4C80.6,5.9,78.8,5.1,77.3,5.1z"
      />
      <path
        d="M86,5.1c-2.5,0-3.9,1.6-3.9,3.7c0,1.5,0.8,2.8,2.3,3.4l-1,0.3c-0.8,0.2-1.1,0.6-1.1,1.2s0.4,0.9,0.8,1.1
		c-0.6,0.3-1.4,0.9-1.4,1.9c0,1.1,1,1.8,2.3,1.8h3.7c1.8,0,2.9-1.3,2.9-2.6c0-1.1-0.8-2-2.5-2h-4.5c-0.2,0-0.3-0.1-0.3-0.3
		c0-0.1,0-0.2,0.2-0.2l3.4-0.8c1.8-0.4,2.8-1.9,2.8-3.5c0-0.8-0.3-1.7-0.9-2.4h1.7V5.5h-2.9C87.3,5.3,86.7,5.1,86,5.1z M84.2,14.8
		h3.8c0.9,0,1.4,0.4,1.4,1.2c0,0.8-0.6,1.5-1.7,1.5h-2.9c-1,0-1.6-0.4-1.6-1.2C83.1,15.6,83.7,15.1,84.2,14.8z M86,11.6
		c-1.1,0-2.5-0.8-2.5-2.8c0-1.9,1.4-2.8,2.5-2.8c1.1,0,2.5,0.8,2.5,2.8C88.5,10.8,87.1,11.6,86,11.6z"
      />
      <path
        d="M98.4,9.8c-1.3-0.5-2.6-0.9-2.6-2.1c0-0.8,0.6-1.5,2-1.5c1.6,0,2.1,1.3,1.9,2.2h1.3c0.2-1.8-1.2-3.2-3.2-3.2
		c-2,0-3.4,1.1-3.4,2.7c0,1.5,1.3,2.3,2.9,2.9c1.3,0.5,2.7,0.9,2.7,2.2c0,1.3-1.2,1.9-2.3,1.9c-1.3,0-2.6-0.8-2.2-2.7h-1.4
		c-0.3,2.2,1.3,3.7,3.5,3.7c2.1,0,3.7-1.2,3.7-3.1C101.5,10.9,99.7,10.2,98.4,9.8z"
      />
      <path
        d="M106.5,5.1c-2.5,0-4.2,2.3-4.2,5.4s1.6,5.4,4.3,5.4c2.1,0,3.6-1.5,3.6-3.7h-1.4c0.1,2.1-1.3,2.8-2.3,2.8
		c-1.3,0-2.8-1-2.8-4.3h6.6v-0.4C110.4,7.4,109.1,5.1,106.5,5.1z M103.8,9.7c0.1-2.4,1.3-3.6,2.7-3.6c1.3,0,2.5,1,2.5,3.6H103.8z"
      />
      <path
        d="M115.3,5.1c-2.2,0-3.8,1.2-3.7,3.6h1.3c-0.2-1.8,1-2.6,2.2-2.6c1.4,0,2.5,0.7,2.5,2.3V9l-2.4,0.7
		c-1.9,0.5-4,1.1-4,3.5c0,1.6,1.5,2.6,3.2,2.6c1.6,0,2.6-0.8,3.2-1.8v1.4h1.3V8.2C119,6.7,117.9,5.1,115.3,5.1z M117.7,11.6
		c0,1.3-0.9,3.3-3,3.3c-1.3,0-2-0.9-2-1.8c0-1.8,1.4-2,5-3V11.6z"
      />
      <path
        d="M122.9,3h-1c0,1.6-0.3,2.5-1.9,2.6v0.7h1.5V14c0,1.5,1,2.2,2.7,1.7v-1c-0.9,0.3-1.4,0-1.4-0.8V6.4h1.9V5.4
		h-1.9V3z"
      />
      <path d="M127.3,2.1c-0.6,0-1,0.5-1,1.1c0,0.6,0.5,1.1,1,1.1c0.6,0,1-0.5,1-1.1C128.3,2.6,127.9,2.1,127.3,2.1z" />
      <rect x="126.6" y="5.4" width="1.3" height="10.1" />
      <path
        d="M134.8,5.1c-1.3,0-2.4,0.6-3.1,1.7V5.4h-1.3v10.1h1.3V10c0-2.7,1.6-3.9,2.9-3.9c1,0,2.2,0.6,2.2,2.5v6.8h1.3
		V8.4C138,5.9,136.3,5.1,134.8,5.1z"
      />
      <path
        d="M143.4,5.1c-2.5,0-3.9,1.6-3.9,3.7c0,1.5,0.8,2.8,2.3,3.4l-1,0.3c-0.8,0.2-1.1,0.6-1.1,1.2s0.4,0.9,0.8,1.1
		c-0.6,0.3-1.4,0.9-1.4,1.9c0,1.1,1,1.8,2.3,1.8h3.7c1.8,0,2.9-1.3,2.9-2.6c0-1.1-0.8-2-2.5-2h-4.5c-0.2,0-0.3-0.1-0.3-0.3
		c0-0.1,0-0.2,0.2-0.2l3.4-0.8c1.8-0.4,2.8-1.9,2.8-3.5c0-0.8-0.3-1.7-0.9-2.4h1.7V5.5h-2.9C144.7,5.3,144.1,5.1,143.4,5.1z
		 M141.6,14.8h3.8c0.9,0,1.4,0.4,1.4,1.2c0,0.8-0.6,1.5-1.7,1.5h-2.9c-1,0-1.6-0.4-1.6-1.2C140.6,15.6,141.1,15.1,141.6,14.8z
		 M143.4,11.6c-1.1,0-2.5-0.8-2.5-2.8c0-1.9,1.4-2.8,2.5-2.8c1.1,0,2.5,0.8,2.5,2.8C145.9,10.8,144.5,11.6,143.4,11.6z"
      />
      <polygon
        points="163.3,6.3 163.3,5.4 160.7,5.4 160.7,10.4 161.8,10.4 161.8,8.3 163.1,8.3 163.1,7.5 161.8,7.5 
		161.8,6.3 	"
      />
      <path
        d="M153.1,5.4h-1.5v5h1.1V8.4h0.4c0.9,0,1.5-0.7,1.5-1.5C154.6,6.1,154,5.4,153.1,5.4z M152.8,7.6h-0.2V6.3h0.2
		c0.4,0,0.7,0.3,0.7,0.7C153.5,7.3,153.2,7.6,152.8,7.6z"
      />
      <rect
        x="154.2"
        y="7.7"
        transform="matrix(0.4941 -0.8694 0.8694 0.4941 72.599 140.5897)"
        width="5.9"
        height="0.3"
      />
    </g>
  </svg>
);

FooterLogo.defaultProps = {
  color: `black`,
  inject: null
};
FooterLogo.propTypes = {
  color: PropTypes.string,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  })
};

// todo : color
export const Logomark = ({ color, inject }) => (
  <svg
    css={[
      css`
        ${inject?.css || ``}
      `,
      inject?.tw || tw``
    ]}
    x="0px"
    y="0px"
    viewBox="0 0 227 109.2"
    xmlSpace="preserve"
  >
    <polygon points="172.4,9.6 172.4,97.7 191.7,97.7 191.7,60.8 215.2,60.8 215.2,46.5 191.7,46.5 191.7,24.5 218.4,24.5 218.4,9.6 " />
    <g>
      <path
        d="M8.9,9.6v88.2h19.3V63h7.7c16.3,0,27.2-11.6,27.2-26.7c0-15.2-10.9-26.7-27.2-26.7H8.9z M28.1,24.5h3.5
      c7.1,0,12.2,4.7,12.2,11.8s-5.1,11.8-12.2,11.8h-3.5V24.5z"
      />
    </g>
    <rect
      x="55.4"
      y="50.9"
      transform="matrix(0.4945 -0.8692 0.8692 0.4945 7.7794 121.1029)"
      width="105.1"
      height="6"
    />
  </svg>
);

Logomark.defaultProps = {
  color: `black`,
  inject: null
};
Logomark.propTypes = {
  color: PropTypes.string,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  })
};

// todo : color
export const Wordmark = ({ color, inject }) => (
  <svg
    css={[
      css`
        ${inject?.css || ``}
      `,
      inject?.tw || tw``
    ]}
    x="0px"
    y="0px"
    viewBox="0 0 871.6 109.2"
  >
    <g>
      <path
        d="M833.8,41.5c6.2,0,9.6,6.8,9.6,15.6h-19.2C824.2,48.3,827.6,41.5,833.8,41.5 M833.8,29C816.4,29,805,44,805,64.6
		c0,19.8,12.3,35.6,29.9,35.6c15.2,0,26.1-12.2,26.1-26.9h-17.4c0.5,6.6-1.2,14.4-8.8,14.4c-7.1,0-11-7.1-11-20.4h38v-3.9
		C861.4,43.2,850.9,29,833.8,29 M798.3,29c-7.3,0-12.9,5-15.7,10.5v-8.3h-18.1v66.6h18.1v-36c0-12,6.6-15.9,12.5-15.9
		c3.7,0,6.6,1.1,9.3,2.9l2.2-17.4C805.1,30,801.7,29,798.3,29 M756.9,97.8V31.1h-18.1V71c0,10.3-4.6,14.9-10.1,14.9
		c-5.2,0-7.8-4.6-7.8-11.1V31.1h-18.1v45.4c0,19,11.7,23.3,18.8,23.3c7.8,0,13.8-4,17.2-9.7v7.6H756.9z M686.4,31.1V13.2h-10.8
		c0,11.7-5.4,18.3-14.7,18.9v11.3h7.5v37.1c0,13.9,10.1,22.7,26,18.8V85.9c-5.1,1.5-8-0.7-8-5.2V43.5h10.2V31.1H686.4z M655.9,97.8
		V31.1h-18.1V71c0,10.3-4.6,14.9-10.1,14.9c-5.2,0-7.8-4.6-7.8-11.1V31.1h-18.1v45.4c0,19,11.7,23.3,18.8,23.3
		c7.8,0,13.8-4,17.2-9.7v7.6H655.9z M598.7,24.4V9.5h-46.1v88.3H572v-37h23.5V46.5H572v-22H598.7z M536,31.1V13.2h-10.8
		c0,11.7-5.4,18.3-14.7,18.9v11.3h7.5v37.1c0,13.9,10.1,22.7,26,18.8V85.9c-5.1,1.5-8-0.7-8-5.2V43.5h10.2V31.1H536z M487.7,29
		c-7.8,0-13.8,4-17.2,9.7v-7.6h-18.1v66.6h18.1V57.9c0-10.3,4.6-14.9,10.1-14.9c5.2,0,7.8,4.6,7.8,11.1v43.7h18.1V52.3
		C506.5,33.3,494.8,29,487.7,29 M418.2,41.5c6.2,0,9.6,6.8,9.6,15.6h-19.2C408.6,48.3,412,41.5,418.2,41.5 M418.2,29
		c-17.4,0-28.8,14.9-28.8,35.6c0,19.8,12.3,35.6,29.9,35.6c15.2,0,26.1-12.2,26.1-26.9H428c0.5,6.6-1.2,14.4-8.8,14.4
		c-7.1,0-11-7.1-11-20.4h38v-3.9C445.8,43.2,435.3,29,418.2,29 M365,29c-7.8,0-13.8,4-17.2,9.7v-7.6h-18.1v66.6h18.1V57.9
		c0-10.3,4.6-14.9,10.1-14.9c5.2,0,7.8,4.6,7.8,11.1v43.7h18.1V52.3C383.8,33.3,372.1,29,365,29 M304,75.1c0,7.2-3.7,12.5-10.3,12.5
		c-5,0-8.2-3-8.2-8.1c0-7.3,6.7-9,18.6-13.2V75.1z M295.9,29c-19.2,0-26.8,10.6-25.9,25.8h17.2c-1.1-8,1.6-13.3,8.2-13.3
		c6.1,0,8.6,4.6,8.6,11v2.1l-10.7,3.6c-19.4,6.6-25.5,13.6-25.5,23.8c0,12,8.5,18.3,19.7,18.3c7.2,0,13.1-2.6,16.6-9v6.5h18.1V51.6
		C322.1,41.5,316.9,29,295.9,29 M207.2,29c-7.8,0-13.8,4-17.2,9.7v-7.6h-18.1v66.6H190V57.9c0-10.3,4.6-14.9,10.1-14.9
		c5.2,0,7.8,4.6,7.8,11.1v43.7H226V57.9C226,47.6,230.6,43,236,43c5.2,0,7.8,4.6,7.8,11.1v43.7h18.1V52.3c0-19-11.7-23.3-18.8-23.3
		c-8.6,0-15.3,4.4-19.4,11C220,31.3,212.4,29,207.2,29 M158.6,29c-7.3,0-12.9,5-15.7,10.5v-8.3h-18.1v66.6h18.1v-36
		c0-12,6.6-15.9,12.5-15.9c3.7,0,6.6,1.1,9.3,2.9l2.2-17.4C165.3,30,162,29,158.6,29 M90.6,41.5c6.2,0,9.6,6.8,9.6,15.6H81
		C81,48.3,84.4,41.5,90.6,41.5 M90.6,29C73.2,29,61.9,44,61.9,64.6c0,19.8,12.3,35.6,29.9,35.6c15.2,0,26.1-12.2,26.1-26.9h-17.4
		c0.5,6.6-1.2,14.4-8.8,14.4c-7.1,0-11-7.1-11-20.4h38v-3.9C118.3,43.2,107.7,29,90.6,29 M28.1,24.4h3.5c7.1,0,12.2,4.7,12.2,11.8
		c0,7.1-5.1,11.8-12.2,11.8h-3.5V24.4z M8.8,9.5v88.3h19.3V63h7.7c16.3,0,27.3-11.6,27.3-26.8S52.1,9.5,35.8,9.5H8.8z"
        fill={color}
      />
    </g>
  </svg>
);

Wordmark.defaultProps = {
  color: `black`,
  inject: null
};
Wordmark.propTypes = {
  color: PropTypes.string,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  })
};
