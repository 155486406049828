/* eslint-disable import/prefer-default-export */

import React, { useContext } from "react";
import { PropTypes } from "prop-types";
import tw, { css, theme } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";

/**
 * -----------------------------------------------------------------------------
 * Transform the screen value from the DocumentContext. Required to sanitise
 * desktop values; typically md/lg/xl will use the same font size.
 * @return {node} Sanitised screen variable
 */
export const adjustScreen = (screen) =>
  screen === `lg` || screen === `xl` ? `md` : screen;

/**
 * -----------------------------------------------------------------------------
 * Receive a h1-h6 node from the style guide. The type of node returned is
 * determined by the level supplied.
 * @param  {node}    children  Inner text
 * @param  {string}  font      The font style (accepts 1, 2, 3, 4, b1, b2, b3)
 * @param  {object}  inject    Additional Emotion/Tailwind CSS
 * @param  {string}  level     The heading level (accepts 1, 2, 3, 4, 5, 6)
 * @param  {boolean} serif     Force the Tailwind serif family if true
 * @return {node}              The resulting <h?> node
 */
export const Heading = ({ children, font, inject, level, serif }) => {
  const { screen } = useContext(DocumentContext);
  const adjustedScreen = adjustScreen(screen);

  let fontFamily = theme`fontFamily`.head.join();
  let fontKey = `h${font}`;

  if (serif) {
    fontFamily = theme`fontFamily`.serif.join();
  } else if (font?.toString()?.indexOf(`b`) !== -1) {
    fontFamily = theme`fontFamily`.body.join();
    fontKey = font;
  } else {
    fontFamily = theme`fontFamily`.head.join();
  }

  const tailwindConfig = theme`fontSize`[
    `${fontKey}${adjustedScreen !== `xs` ? `-${adjustedScreen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily,
      fontSize: tailwindConfig[0],
      ...tailwindConfig[1]
    };
  }

  const H = `h${level}`;

  return (
    <H
      css={[
        css`
          ${fontCSS}
          ${inject?.css || ``}
        `,
        inject?.tw || tw``
      ]}
    >
      {children}
    </H>
  );
};

Heading.defaultProps = {
  font: `1`,
  inject: null,
  level: `2`,
  serif: false
};
Heading.propTypes = {
  children: PropTypes.node.isRequired,
  font: PropTypes.string,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  level: PropTypes.string,
  serif: PropTypes.bool
};

/**
 * -----------------------------------------------------------------------------
 * Receive a <p> (body) node from the style guide.
 * @param  {node}    children  Inner text
 * @param  {string}  font      The font style (accepts 1, 2, 3)
 * @param  {object}  inject    Additional Emotion/Tailwind CSS
 * @param  {boolean} serif     Determines whether to use the serif Tailwind font
 * @return {node}              The resulting <p> node
 */
export const Body = ({ children, font, inject, serif }) => {
  const { screen } = useContext(DocumentContext);
  const adjustedScreen = adjustScreen(screen);

  let fontFamily;
  let fontKey = `b${font}`;

  if (serif) {
    fontFamily = theme`fontFamily`.serif.join();
  } else if (font?.toString()?.indexOf(`h`) !== -1) {
    fontFamily = theme`fontFamily`.head.join();
    fontKey = font;
  } else {
    fontFamily = theme`fontFamily`.body.join();
  }

  const tailwindConfig = theme`fontSize`[
    `${fontKey}${adjustedScreen !== `xs` ? `-${adjustedScreen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily,
      fontSize: tailwindConfig[0],
      fontWeight: 300,
      ...tailwindConfig[1]
    };
  }

  return (
    <p
      css={[
        css`
          ${fontCSS}
          ${inject?.css || ``}
        `,
        inject?.tw || tw``
      ]}
    >
      {children}
    </p>
  );
};

Body.defaultProps = {
  font: `1`,
  inject: null,
  serif: false
};
Body.propTypes = {
  children: PropTypes.node.isRequired,
  font: PropTypes.string,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  serif: PropTypes.bool
};

/**
 * -----------------------------------------------------------------------------
 * Receive a button-styled node from the style guide.
 * @param  {node}   children  Inner text
 * @param  {object} inject    Additional Emotion/Tailwind CSS
 * @param  {string} node      Node type; <span> by default
 * @return {node}             The resulting <?> node (defaults to <span>)
 */
export const Button = ({ children, inject, node }) => {
  const { screen } = useContext(DocumentContext);
  const adjustedScreen = adjustScreen(screen);
  const tailwindConfig = theme`fontSize`[
    `button${adjustedScreen !== `xs` ? `-${adjustedScreen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily: theme`fontFamily`.body.join(),
      fontSize: tailwindConfig[0],
      ...tailwindConfig[1]
    };
  }

  const B = `${node}`;

  return (
    <B
      css={[
        css`
          ${fontCSS}
          ${inject?.css || ``}
        `,
        inject?.tw || tw``
      ]}
    >
      {children}
    </B>
  );
};

Button.defaultProps = {
  inject: null,
  node: `span`
};
Button.propTypes = {
  children: PropTypes.node.isRequired,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  node: PropTypes.string
};

/**
 * -----------------------------------------------------------------------------
 * Receive a <p> (caption) node from the style guide.
 * @param  {node}   children  Inner text
 * @param  {object} inject    Additional Emotion/Tailwind CSS
 * @param  {string} node      Node type (<span> by default)
 * @return {node}             The resulting node
 */
export const Caption = ({ children, inject, node }) => {
  const { screen } = useContext(DocumentContext);
  const adjustedScreen = adjustScreen(screen);
  const tailwindConfig = theme`fontSize`[
    `caption${adjustedScreen !== `xs` ? `-${adjustedScreen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily: theme`fontFamily`.body.join(),
      fontSize: tailwindConfig[0],
      ...tailwindConfig[1]
    };
  }

  const C = `${node}`;

  return (
    <C
      css={[
        css`
          ${fontCSS}
          ${inject?.css || ``}
        `,
        inject?.tw || tw``
      ]}
    >
      {children}
    </C>
  );
};

Caption.defaultProps = {
  inject: null,
  node: `span`
};
Caption.propTypes = {
  children: PropTypes.node.isRequired,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  node: PropTypes.string
};
