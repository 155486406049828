import { graphql, useStaticQuery } from "gatsby";

const useSanityShopifyProducts = () => {
  const data = useStaticQuery(graphql`
    query useSanityShopifyProducts {
      allSanityShopifyProduct {
        edges {
          node {
            id
            handle
            title
            description

            collections {
              title
            }

            image {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }

            options {
              name

              values {
                value
              }
            }

            sourceData {
              id
              handle
              title
              vendor

              images {
                edges {
                  node {
                    originalSrc
                  }
                }
              }
            }

            variants {
              id
              title

              image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }

              sourceData {
                id
                sku
                title

                priceV2 {
                  amount
                  currencyCode
                }

                selectedOptions {
                  name
                  value
                }
              }
            }

            shopifyProduct {
              id
              handle
              title
              description

              images {
                originalSrc
              }

              variants {
                id
                sku
                title
                price

                image {
                  originalSrc
                }

                selectedOptions {
                  name
                  value
                }

                presentmentPrices {
                  edges {
                    node {
                      price {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data;
};

export default useSanityShopifyProducts;
